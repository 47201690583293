<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { encodeQuery } from 'zeed'
import iconEmail from '../assets/email.svg'
import iconLinkedin from '../assets/linkedin.svg'
import iconMastodon from '../assets/mastodon.svg'
import iconPocket from '../assets/pocket.svg'
import iconX from '../assets/x.svg'
import messages from './app-share.locale.json'

import './app-share.styl'

const props = defineProps<{
  url?: string
  text?: string
}>()

// See https://listmonk.app/docs/apis/subscribers/#post-apipublicsubscription
const { t } = useI18n({ messages })

const share = computed(() => {
  const url = props.url ?? location.href
  const text = props.text ?? ''
  const textWithUrl = [text, url].join('\n\n')
  return {
    pocket: `https://getpocket.com/save?${encodeQuery({ url })}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?${encodeQuery({ u: url })}`,
    twitter: `https://twitter.com/intent/tweet?${encodeQuery({ url, text })}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?${encodeQuery({ url })}`,
    reddit: `https://reddit.com/submit?${encodeQuery({ url })}`,
    email: `mailto:?${encodeQuery({ subject: 'Hello', body: textWithUrl })}`,
    mastodon: `https://mastodonshare.com/?${encodeQuery({ url, text })}`,
  }
})
</script>

<template>
  <div class="app-share">
    <a aria-label="Email" :href="share.email" :tooltip="t('share.email')" target="_blank"><iconEmail /></a>
    <a aria-label="Pocket" :href="share.pocket" :tooltip="t('share.pocket')" target="_blank"><iconPocket /></a>
    <!-- <a aria-label=Facebook" :href="share.facebook" :tooltip="t('share.facebook')" target="_blank"><iconFacebook /></a> -->
    <!-- <a aria-label=Reddit" :href="share.reddit" :tooltip="t('share.reddit')" target="_blank"><iconReddit /></a> -->
    <a aria-label="LinkedIn" :href="share.linkedin" :tooltip="t('share.linkedin')" target="_blank"><iconLinkedin /></a>
    <a aria-label="Twitter" :href="share.twitter" :tooltip="t('share.twitter')" target="_blank"><iconX /></a>
    <a aria-label="Mastodon" :href="share.mastodon" :tooltip="t('share.mastodon')" target="_blank"><iconMastodon /></a>
  </div>
</template>
