import { useMediaQuery } from '@vueuse/core'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { throttle } from 'zeed'
import { emitter } from '../lib/emitter'
import { isServer } from './client'

const PAGE_OFFSET = 40 // -20 // -92

interface Header {
  level: number
  title: string
  link: string
  active: boolean
  id: string
  parent: string
  open: boolean
}

/** Just the textContent  */
function serializeHeader(h: Element): string {
  let ret = ''
  for (const node of h.childNodes) {
    if (node.nodeType === 1)
      ret += node.textContent
    else if (node.nodeType === 3)
      ret += node.textContent
  }
  return ret.trim()
}

export function useOutline() {
  // const log: LoggerInterface = Logger('outline')

  const outlineAllow = ref(true)
  const outlineHeaders = ref<Header[]>([])

  if (isServer) {
    return {
      isOutline: ref(false),
      outlineAllow,
      outlineHeaders,
    }
  }

  const isWide = useMediaQuery('(min-width: 1280px)')
  const isOutline = computed(() => outlineHeaders.value.length > 3 && isWide.value)

  /** Get headers 2 & 3 and mark active */
  function getHeaders(el: Element): Header[] {
    const parents = ['', '', '', '', '', '']
    let foundActive = false
    const scrollTop = window.scrollY
    let last: Header
    const headers = ([...el.querySelectorAll('h2[id],h3[id],h4[id]')] as HTMLElement[])
      .filter(el => el.id && el.hasChildNodes())
      .map((el) => {
        const level = Number(el.tagName[1])
        const top = (el.offsetTop ?? 0) - PAGE_OFFSET
        const active = foundActive ? false : scrollTop <= top
        const id = `outline-header-${el.id}`
        parents[level] = id
        if (active && last) {
          last.active = true
          foundActive = true
        }
        last = {
          title: serializeHeader(el),
          link: `#${el.id}`,
          level,
          active: false,
          id,
          parent: parents[level - 1],
          open: level === 2,
        }
        return last
      })

    let activeItem = headers.find(o => o.active)
    if (activeItem) {
      // Level of active
      headers.forEach((o) => {
        if (o.parent === activeItem?.id)
          o.open = true
      })

      // Level above
      while (activeItem?.parent) {
        const parent = activeItem.parent as string
        headers.forEach((o) => {
          if (o.parent === parent)
            o.open = true
        })
        activeItem = headers.find(o => o.id = parent)
      }
    }

    // log('headers', headers)
    return headers
  }

  async function updateOutline() {
    await nextTick()
    if (!document.querySelector('[data-no-outline]') && outlineAllow.value) {
      const el = document.querySelector('.post-body')
      if (el)
        outlineHeaders.value = getHeaders(el)

      // await nextTick()
      // log('outlineActiveId', outlineActiveId)

      // if (outlineActiveId) {
      //   document.getElementById(outlineActiveId)?.scrollIntoView({
      //     behavior: 'instant',
      //     block: 'center',
      //   })
      // }

      return
    }
    outlineHeaders.value = []
  }

  // const updateOutline = throttle(_updateOutline, { delay: 100 })

  async function updateScroll() {
    if (isOutline.value)
      await updateOutline()
  }

  // Tirggers

  const onScroll = throttle(updateScroll, { delay: 100 })

  onMounted(() => {
    requestAnimationFrame(updateOutline)
    window.addEventListener('scroll', onScroll)
  })

  onUnmounted(() => window.removeEventListener('scroll', onScroll))

  watch(isOutline, updateOutline, { immediate: true })
  watch(outlineAllow, updateOutline)

  emitter.on('didUpdateContent', updateOutline)

  return {
    isOutline,
    outlineAllow,
    outlineHeaders,
  }
}
