<script setup lang="ts">
import type { PostMeta } from '@/_types'
import type { Component } from 'vue'
import { isServer } from '@/lib/client'
import { emitter } from '@/lib/emitter'
import { useOutline } from '@/lib/use-outline'
import { parseFrontmatter } from '@/process/parse-frontmatter'
import { markRaw, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { arraySorted, dayToDate } from 'zeed'
import { useHeadDescription, useHeadTitle } from '../lib/use-app-head-holtwick'
import AppShare from './app-share.vue'

const r = useRoute()

const meta = ref<PostMeta>()
const tags = ref<string[]>([])
const date = ref<string>()
const comp = ref<Component>()
// const head = ref<any>()

const { locale, t } = useI18n()

// useHead(head)

const { isOutline, outlineAllow, outlineHeaders } = useOutline()

const { postsPath, shareIntro } = globalThis.siteConfig

async function load() {
  const module = await (r.meta.comp as any)()
  const { default: component, ...frontmatter } = module
  comp.value = markRaw(component)

  meta.value = parseFrontmatter(frontmatter, r.path) // slug is not used, therefore src=''
  tags.value = meta.value.tags ? arraySorted(meta.value.tags) : []

  date.value = meta.value.day
    ? dayToDate(meta.value.day).toLocaleDateString(meta.value.lang ?? 'en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    : undefined

  if (meta.value) {
    const post = meta.value
    const title = post.title
    const description = post.desc

    outlineAllow.value = post.outline !== false

    useHeadTitle(title)
    useHeadDescription(description ?? t('site.description'))

    // const info: any = {
    //   title,
    //   meta: [
    //     { property: 'og:title', content: title },
    //     { name: 'twitter:title', content: title },
    //   ],
    // }

    // if (description) {
    //   info.meta.push(
    //     { name: 'description', content: description },
    //     { name: 'twitter:description', content: description },
    //     { property: 'og:description', content: description },
    //   )
    // }

    // head.value = info
  }

  await nextTick()

  // nextTick().then(() => emitter.call.didUpdateContent())

  await emitter.emit('didUpdateContent')
}

watch(() => r.meta.comp, load, { immediate: true })

if (isServer)
  await load()
else
  void load()
</script>

<template>
  <ClientOnly>
    <aside v-if="isOutline">
      <div class="app-outline">
        <ul>
          <template v-for="h in outlineHeaders" :key="h.link">
            <template v-if="h.open">
              <li :class="`app-outline-level-${h.level}`">
                <a :id="h.id" :href="h.link" :class="{ active: h.active }">{{ h.title }}</a>
              </li>
            </template>
          </template>
        </ul>
      </div>
    </aside>
  </ClientOnly>
  <article
    :data-draft="meta?.draft"
    :data-archive="meta?.archive"
    :data-priority="meta?.priority ?? 1"
    :data-slug="meta?.slug ?? 'page'"
    class="prose"
    :class="{
      'post-hidden': !!(meta?.draft || meta?.archive),
      'post': !isOutline,
      'post-outline': isOutline,
    }"
  >
    <h1 v-if="meta?.heading || meta?.title " class="post-title">
      {{ meta.heading ?? meta.title }}
    </h1>
    <div v-if="tags.length || (shareIntro && $route.meta.mode === 'post')" class="post-meta">
      <div v-if="tags.length" class="post-tags tags">
        <template v-for="tag in tags" :key="tag">
          <router-link :to="`/${locale}/tag/${tag}`" class="tags-item">
            {{ tag }}
          </router-link>
        </template>
      </div>
      <div class="_space" />
      <ClientOnly>
        <template v-if="shareIntro && $route.meta.mode === 'post'">
          <AppShare :text="meta?.heading ?? meta?.title" />
        </template>
      </ClientOnly>
    </div>
    <div class="post-body">
      <component :is="comp" />
      <p v-if="date">
        <em>{{ $t("site.published") }}  {{ date }}</em>
      </p>
    </div>
    <template v-if="date">
      <div>&nbsp;</div>
      <div>
        <router-link :to="`/${locale}/${postsPath}`">
          {{ $t("site.back") }}
        </router-link>
      </div>
    </template>
  </article>
</template>
